import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import StudioTropik from "../../components/Portfolio/StudioTropik"

const StudioTropikPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <StudioTropik />
  </Layout>
)

export default StudioTropikPage
